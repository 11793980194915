import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import tsParticlesOptions from "./utils/tsParticlesOptions";
import "./App.css";
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import MailingList from "./components/MailingList/MailingList";

const App = () => {
  const customInit = useCallback(async (engine) => {
    // this adds the bundle to tsParticles
    await loadFull(engine);
  }, []);
  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
      <header className="mb-auto">
        <div>
          <h1 className=" float-md-start mb-0">Lunchboxd</h1>
          <Nav />
        </div>
      </header>

      <div className="px-2 py-3">
        <h3>Cumming soon.</h3>
        <p>Someone please come up with some snappy paragraph here, thanks 😘</p>
      </div>
      <MailingList />

      <Footer />
      <Particles options={tsParticlesOptions} init={customInit} />
    </div>
  );
};

export default App;
