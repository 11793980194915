const Nav = () => {
  return (
    <nav className="nav nav-masthead justify-content-center float-md-end">
      <a className="nav-link active" aria-current="page" href="/#">
        Home
      </a>
    </nav>
  );
};

export default Nav;
