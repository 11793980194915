const Footer = () => {
  return (
    <footer className="mt-auto text-white-50">
      <p>
        For enquiries, contact{" "}
        <a href="Jake@lunchboxd.co.uk" className="text-white">
          Jake@lunchboxd.co.uk
        </a>{" "}
        or{" "}
        <a href="https://twitter.com/_lunchboxd" className="text-white">
          @_Lunchboxd
        </a>{" "}
        on Twitter.
      </p>
    </footer>
  );
};

export default Footer;
