import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MailingList = () => {
  const notify = () => {
    toast.success("Thanks! Check your inbox 📫", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    toast.clearWaitingQueue();
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <h2>Don't miss out!</h2>
      <p>
        Stay up to date with the latest developments and get early access to the
        alpha by signing up to the newsletter below!
      </p>
      <div className=" d-flex flex-row my-3">
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Enter your email address"
            aria-label="Recipient's email"
          />
          <Button
            variant="outline-secondary"
            id="button-addon2"
            onClick={notify}
          >
            Sign Up
          </Button>
        </InputGroup>
      </div>
    </>
  );
};
export default MailingList;
